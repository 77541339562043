<template>
  <div
    v-if="hasMultipleLocales"
    class="t1-localeControl">
    <ul>
      <li
        v-for="locale in locales"
        :key="locale.code">
        <LocaleItem
          :locale="locale" />
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import LocaleItem from './LocaleItem.vue';

export default {
  components: { LocaleItem },
  computed: {
    ...mapState('generic', ['locales']),
    activeLocale() {
      return this.locales.find((locale) => locale.isActive);
    },
    hasMultipleLocales() {
      return this.locales.length > 1;
    }
  }
};
</script>

<style lang="css">
.t1-localeControl {
  position: relative;

  ul {
    @apply flex -mx-2;

    li {
      @apply relative px-2;

      &:not(&:last-child) {
        :after {
          content: "/";
          @apply absolute left-full;
          transform: translateX(-50%);
          top: 0;
        }
      }
    }
  }
}
</style>
