import { cloneObject, handleFixedHeaderSpacing, handleScrollbarWidth } from 'utils';
import '@fortawesome/fontawesome-free/js/all';
import 'lazysizes';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks';
import notification from 'utils/noty';
import logger from 'utils/logger';
import 'tailwindcss/utilities.css';
import 'styles/includes/tailwind-utilities.css';
import LocaleControl from 'components/generic/LocaleControl';
import MobileMenu from 'components/generic/MobileMenu';

logger.init();

export default {
  install(Vue) {
    Vue.prototype.$cloneObject = cloneObject;
    Vue.prototype.$notification = notification;
    Vue.config.productionTip = false;
    Vue.component('Tooltip', () => import(/* webpackChunkName: "tooltip" */ 'components/generic/Tooltip'));
    Vue.component('Modal', () => import(/* webpackChunkName: "modal" */ 'components/generic/Modal'));
    Vue.component('Login', () => import(/* webpackChunkName: "login" */ 'components/generic/Login'));
    Vue.component('AccessCodeModal', () => import(/* webpackChunkName: "accessCodeModal" */ 'components/generic/AccessCodeModal'));
    Vue.component('LoginLogoutButton', () => import(/* webpackChunkName: "loginLogoutButton" */ 'components/generic/LoginLogoutButton'));
    Vue.component('CookieNotice', () => import(/* webpackChunkName: "cookieNotice" */ 'components/cookie-notice/CookieNotice.vue'));
    Vue.component('ScrollToTop', () => import(/* webpackChunkName: "scrollToTop" */ 'components/generic/ScrollToTop.vue'));
    Vue.component('LocaleControl', LocaleControl);
    Vue.component('MobileMenu', MobileMenu);

    // Trigger DOM methods on nextTick as the calculation may be wrong otherwise
    // (also the resize observer will not work if executed before the vm is mounted)
    Vue.nextTick(() => {
      handleFixedHeaderSpacing();
      handleScrollbarWidth();
    });
  }
};
